import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const TOKEN = 'token';
const REFRESHTOKEN = 'refreshToken';
const TYPE = 'type';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(
    private router: Router,
  ) { }

  public signOut() {
    window.sessionStorage.clear();
    this.router.navigateByUrl(
      this.router.createUrlTree([''])
    );
    /*agregar limpiar todos los datos de forma completa */
  }

  public NavigateOut() {
    this.router.navigateByUrl(
      this.router.createUrlTree([''])
    );
  }
    
  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN);
    window.sessionStorage.setItem(TOKEN, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN);
  }

  public saveRefreshToken(refreshToken: string) {
    window.sessionStorage.removeItem(REFRESHTOKEN);
    window.sessionStorage.setItem(REFRESHTOKEN, refreshToken);
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem(REFRESHTOKEN);
  }

  public saveType(Type: string) {
    window.sessionStorage.removeItem(TYPE);
    window.sessionStorage.setItem(TYPE, Type);
  }

  public getType(): string {
    return sessionStorage.getItem(TYPE);
  }
}
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class StateService {

  /* ViewPort */
  public smallViewPort = false;
  public viewPortChange: Subject<boolean> = new Subject<boolean>();
  public viewPortHeight: number = 0;
  public viewPortWidth: number = 0;

  public get getSmallViewPortValue() {
    return this.smallViewPort;
  }

  public setSmallViewPortValue(Bool) {
    this.smallViewPort = Bool;
  }

  /*Duracion de la Jornada */
  public DuracionDeLaHornadaChange: Subject<boolean> = new Subject<boolean>();
  public DuracionDeLaHornadaTemporalValues = {
    Diurna: [8, 0],
    Mixto: [7, 30],
    Nocturna: [8, 0]
  };

  public currentTrabajador: any = {};
  public currentTrabajadorChange: Subject<any> = new Subject<any>();
  public eliminarHorario: Subject<any> = new Subject<any>();
  public resetTrabajadorAlreadyImported: Subject<boolean> = new Subject<boolean>();

}

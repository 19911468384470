import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { TokenStorageService } from './tokenStorage.service'
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { empty } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private tokenStorageService: TokenStorageService
        ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenStorageService.getToken();
        const refreshToken = this.tokenStorageService.getRefreshToken();
        if (token) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", token).set("AuthorizationRefresh", refreshToken)
            });
            return next.handle(cloned).pipe(
                map((event: any) => {
                    if (event instanceof HttpResponse) {
                    }
                    return event;
                }),
                catchError((err, caught) => {
                    console.log(err)
                    if (err.status === 500) console.log(('Hubo un error en el servidor'));
                    if (err.status === 401) {
                            if (err.error.token && err.error.refreshToken) {
                                this.tokenStorageService.saveToken(err.error.token);
                                this.tokenStorageService.saveRefreshToken(err.error.refreshToken);
                                this.tokenStorageService.saveType(err.error.type);
                                const clonedRefresh = req.clone({
                                    headers: req.headers.set("Authorization", err.error.token).set("AuthorizationRefresh", err.error.refreshToken)
                                });
                                return next.handle(clonedRefresh)
                            } else {
                                if(err.error.message === 'Invalid Token'){
                                    this.tokenStorageService.signOut();
                                }
                            }

                    }
                    return empty();
                })

            )
        }
        return next.handle(req).pipe(catchError((err, caught) => {
            if (err.status === 500) console.log(('Hubo un error en el servidor'));
            if (err.status === 401) {
                if(err.error.message === 'Invalid Token'){
                    this.tokenStorageService.signOut();
                }
            }
            return empty();
        }));
    }


}

import { Component, OnInit } from '@angular/core';
import { StateService } from './services/state.Service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'SIP';
  viewportWidth = null;

  constructor(private stateService: StateService) {
    this.stateService.viewPortHeight = window.innerHeight;
    this.stateService.viewPortWidth = window.innerWidth;
    window.addEventListener('resize', (event) =>{
      this.stateService.viewPortHeight = window.innerHeight;
      this.stateService.viewPortWidth = window.innerWidth;
    });
    this.viewportWidth = window.matchMedia("(max-width: 1000px)").addListener((x) => {
      if (x.matches) { // If media query matches
        this.stateService.smallViewPort = true;
        this.stateService.viewPortChange.next(true);
      } else {
        this.stateService.smallViewPort = false;
        this.stateService.viewPortChange.next(false);
      }
    });

    this.stateService.smallViewPort = window.innerWidth <= 1000;

  }

  ngOnInit() {}

}

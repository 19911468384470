import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'Login',
    loadChildren: () =>
      import('./login/login.module').then(mod => mod.LoginModule),
  },
  {
    path: 'Workarea',
    loadChildren: () =>
      import('./workarea/workarea.module').then(mod => mod.WorkareaModule),
  },
  {
    path: '',
    redirectTo: 'Login',
    pathMatch: 'full',
  },
  {path: '**', redirectTo: 'Login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
